// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-wingscms-hummingbird-src-templates-page-js": () => import("../node_modules/@wingscms/hummingbird/src/templates/Page.js" /* webpackChunkName: "component---node-modules-wingscms-hummingbird-src-templates-page-js" */),
  "component---node-modules-wingscms-hummingbird-src-templates-page-home-js": () => import("../node_modules/@wingscms/hummingbird/src/templates/PageHome.js" /* webpackChunkName: "component---node-modules-wingscms-hummingbird-src-templates-page-home-js" */),
  "component---node-modules-wingscms-hummingbird-src-templates-campaign-js": () => import("../node_modules/@wingscms/hummingbird/src/templates/Campaign.js" /* webpackChunkName: "component---node-modules-wingscms-hummingbird-src-templates-campaign-js" */),
  "component---node-modules-wingscms-hummingbird-src-templates-campaign-confirmed-js": () => import("../node_modules/@wingscms/hummingbird/src/templates/CampaignConfirmed.js" /* webpackChunkName: "component---node-modules-wingscms-hummingbird-src-templates-campaign-confirmed-js" */),
  "component---node-modules-wingscms-hummingbird-src-templates-404-js": () => import("../node_modules/@wingscms/hummingbird/src/templates/404.js" /* webpackChunkName: "component---node-modules-wingscms-hummingbird-src-templates-404-js" */)
}

