module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.zes25.nl"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#24A42E","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"/opt/build/repo/node_modules/@wingscms/hummingbird/lib/typography.js"},
    },{
      plugin: require('../node_modules/@wingscms/hummingbird/gatsby-browser.js'),
      options: {"plugins":[],"wings":{"project":"LOl7Zq4vVWcAZ5pjOBRx","appKey":"app_key_80931ad4c1e2b2b58bb4ca9cca84fb20a3cf102288c654aa"},"blockRobots":false,"campaigns":{},"design":{"headerFontFamily":"Poppins, sans-serif","faviconImageUrl":"https://files.bolster.digital/Zes2025_NoBG-1586158064.svg","primaryColor":"#66b077","formBackgroundColor":"#66b077","footerBackgroundColor":"#66b077","logoImageUrl":"https://files.bolster.digital/Zes25_SideBySide-1586164838.svg","linkColor":"#66b077","backgroundColor":"#f8f8f8","elementBackgroundColor":"#fff","introFontSize":"1.4","footerTextColor":"#fff","blockquoteBackgroundColor":"#fff","landingSectionTitleColor":"#fff","navigationBackgroundColor":"#fff","colorChapterIntro":"#fff","counterBackgroundColor":"#fff","pullquoteColor":"#66b077","navigationIconColor":"#66b077","navigationColor":"#66b077"},"typography":{"headerFontFamily":["Poppins","sans-serif"],"bodyFontFamily":["Open Sans","sans-serif"],"googleFonts":[{"name":"Poppins","styles":["400","400i","700","700i"]},{"name":"Open Sans","styles":["400","400i","700","700i"]}]},"footer":{"title":"ZES25","logoUrl":"","logoLink":"","columns":[{"title":"Info","rows":[{"type":"link","url":"/over-ons","content":"Over ons"},{"type":"link","url":"/faq","content":"FAQ"},{"type":"link","url":"/deelnemers","content":"Deelnemers"}]},{"title":"Doe mee","rows":[{"type":"link","url":"/petitions/aansluiten","content":"Aansluiten"}]},{"title":"Overig","rows":[{"type":"link","url":"/privacy","content":"Privacy"}]}]}},
    }]
